import {
  inject,
  bindable,
  BindingEngine,
} from 'aurelia-framework';

import './messaging-conversation-files.scss';

@inject(BindingEngine)
export class MessagingConversationFiles {
  @bindable self;
  @bindable loading;
  @bindable conversation;
  totalMessagesObservable

  @bindable onConversationFilesClicked;
  @bindable onOpenPreview;


  constructor(_BindingEngine) {
    this.bindingEngine = _BindingEngine;

    this.filesFilters = [
      { name: 'images', selected: true },
      { name: 'files', selected: false },
      { name: 'forms', selected: false }
    ]
    this.selectedFilter = 'images';

    this.messages = [];
    this.messagesList = [];

    this.openPreview = this.openPreview.bind(this);
    this.toggleConversationFiles = this.toggleConversationFiles.bind(this);
  }

  attached() {
    if (this.conversation && this.conversation.messages) {
      this.messagesList = this.conversation.messages;
      this.filterAttachments();
      this.totalMessagesObservable = this.bindingEngine
        .propertyObserver(this.conversation, 'totalMessages')
        .subscribe((newValue, oldValue) => {
          this.totalMessagesChanged(newValue, oldValue)
        });
    }
  }

  totalMessagesChanged() {
    this.messagesList = this.conversation.messages;
    this.filterAttachments();
  }

  filterAttachments = () => {
    this.messagesList.forEach((elem) => {
      if (elem.attachments && elem.attachments.length > 0) {
        switch (this.selectedFilter) {
          case 'images':
            elem.images = elem.attachments.filter(e => e && e.type.match('image/*'))
            break;
          case 'forms':
            elem.forms = elem.attachments.filter(e => e && e.type.match('application/json'))
            break;
          default:
            elem.files = elem.attachments.filter(e => e && !e.type.match('application/json') && e.type.match('application/*'));
        }
      }
    })
    this.messages = this.messagesList;
  }

  openPreview(form) {
    this.onOpenPreview(form);
  }

  imageExtension(file) {
    return (file && file.type.match('image/*')) ? true : false
  }

  fileUrl(file) {
    let url = null;

    if (typeof file === 'string') {
      url = file;
    }

    if (file instanceof Blob) {
      url = URL.createObjectURL(file);
    }

    if (url) {
      return url
    }

  }

  selectFilter(item) {
    this.filesFilters.map(e => item.name === e.name ? e.selected = true : e.selected = false);
    const { name } = this.filesFilters.find(i => i.selected);
    this.selectedFilter = name;
    this.filterAttachments();
  }
  toggleConversationFiles(e) {
    this.onConversationFilesClicked(e);
  }

  detached() {
    this.totalMessagesObservable.dispose();
  }
}
