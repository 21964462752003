import {
  bindable,
  computedFrom
} from 'aurelia-framework';
import {
  Conversation,
  Message
} from 'services/api/models/Messaging';
import messagingService from '../../../../services/api/messagingService';

import './messaging-conversations.scss';

export class MessagingConversations {
  contacts;
  @bindable conversations;
  conversationsList;
  @bindable users;
  @bindable self;
  @bindable loading;

  contexts;
  activeContext;
  datetimeOptions;

  @bindable onConversationSelected;
  @bindable onNewGroupSelected;

  constructor() {
    this.datetimeOptions = {
      sameDay: "LT",
      lastDay: "[Yesterday]",
      lastWeek: "L",
      sameElse: "L"
    }
    this.conversationsList = [];

    this.contexts = {
      conversations: {
        title: 'conversations',
        filter: ''
      },
      contacts: {
        title: 'contacts',
        filter: ''
      }
    }

    this.activeContext = this.contexts.conversations;

    this.toggleContexts = this.toggleContexts.bind(this);
    this.createConversation = this.createConversation.bind(this);
    this.openConversation = this.openConversation.bind(this);
  }

  usersChanged() {
    if (this.users && this.self) {
      this.contacts = this.users.filter(u => u.userId != this.self.id && u.userId);
    }
  }
  
  // selfChanged() {
  //   console.log('2');
  //   if (this.users && this.self) {
  //     this.contacts = this.users.filter(u => u.userId != this.self.id);
  //   }
  // }

  conversationsChanged() {
    if (!this.conversations) return;
    this.conversationsList = this.conversations.slice(0);
  }

  toggleContexts(e) {
    this.activeContext.filter = '';
    if (this.activeContext == this.contexts.contacts) {
      this.activeContext = this.contexts.conversations;
    } else if (this.activeContext == this.contexts.conversations) {
      this.activeContext = this.contexts.contacts;
    }
  }

  createConversation(user) {
    if (!user || !user.userId) return;
    !this.conversations ? this.conversations = [] : '';

    let conversation = this.conversations.find(c => c.type == 'user' && c.users.includes(user.userId.toString()));
    if (!conversation) {
      messagingService.addConversation(user).then(newConversation => {
        this.conversations.push(newConversation);
        this.conversationsList.push(newConversation);
        this.openConversation(newConversation);
      })
    } else {
      this.openConversation(conversation);
    }
  }

  createGroup() {
    this.conversations.forEach(c => (c.selected = false));
    if (this.activeContext != this.contexts.conversations) {
      this.toggleContexts();
    }
    if (this.onNewGroupSelected) {
      this.onNewGroupSelected();
    }
  }

  openConversation(conversation) {
    if (!conversation || !this.conversations) return;
    conversation.status = 'read';
    conversation.badge = 0;
    this.conversations.forEach(c => (c.selected = c.id === conversation.id));
    if (this.activeContext != this.contexts.conversations) {
      this.toggleContexts();
    }

    if (this.onConversationSelected) {
      this.onConversationSelected();
    }
  }

  unbind() {
    if (this.conversations && this.conversations.length > 0) {
      this.conversations = this.conversations.map(e => e.selected = false);
    }
  }
}
