import {
  inject,
  bindable
} from 'aurelia-framework';
import {
  ValidationRules,
  ValidationController
} from 'aurelia-validation';
import messagingService from 'services/api/messagingService';

import './messaging-conversation-settings.scss';
import {
  Conversation
} from 'services/api/models/Messaging';

@inject(ValidationController)
export class MessagingConversationSettings {
  validationController;
  rules;
  @bindable users;
  @bindable self;
  @bindable conversation;
  @bindable onChangeContext;
  @bindable onUpdateConversation;
  newGroupConversation;
  errorMessage;
  loading;

  constructor(_ValidationController) {
    this.validationController = _ValidationController;
    this.rules = ValidationRules
      .ensure(a => a.name).required().minLength(2).withMessageKey('tooShort')
      .ensure(a => a.users).required().minItems(1)
      .rules;
    this.errorMessage = '';
    this.loading = true;
  }

  attached() {
    this.newGroupConversation = new Conversation({
      type: 'group',
      name: 'New Conversation Group',
      users: [],
      usersName: [],
      messages: []
    })
    this.loadData();
  }

  detached() {
    this.newGroupConversation = null;
  }

  loadData() {
    if (!this.conversation) {
      this.conversation = this.newGroupConversation;
    }
    this.loading = false;
  }

  onValidate() {
    return this.validationController
      .validate({
        object: this.conversation,
        rules: this.rules
      })
      .then(result => {
        console.debug('onValidate', result);
        return Promise.resolve(result);
      })
      .catch(err => {
        console.warn('onValidate Error', err);
        return Promise.resolve(false);
      });
  }

  onSave() {
    this.onValidate().then(result => {
      if (!result.valid) throw (result);
      this.loading = true;
      if (!this.conversation.id) {
        this.conversation.id = Math.floor((Math.random() * 999) + 1);
      }
      if(!this.conversation.users.includes(this.self.id)) {
        this.conversation.users.push(this.self.id);
      }
      this.conversation.usersName = [];
      let users = this.users.filter(u => this.conversation.users.includes(u.id));
      users.forEach(u => {
        this.conversation.usersName.push(u.name);
      });

      if (this.onUpdateConversation) {
        this.onUpdateConversation(this.conversation)
        this.loading = false;
      }
      // this.onCancel();
    }).catch((err) => {
      this.errorMessage = (err && err.message) || 'errormessages--incompleteForms';
    });
  }

  onCancel() {
    let conversation = (this.conversation.id) ? this.conversation : null;

    if (this.onChangeContext) {
      this.onChangeContext(conversation);
    }
  }

  onDelete() {
    if (this.onChangeContext) {
      this.onChangeContext();
    }
  }
}
