import './messaging-preview.scss';
import {
  bindable,
} from 'aurelia-framework';

export class MessagingPreview {
  @bindable preview;

  constructor() {

  }
}
