import userService from 'services/api/userService';
import driversService from 'services/api/driversService';
import messagingService from 'services/api/messagingService';
import {
  SortValueConverter
} from 'components/value-converters/filters/sort-list-vc';
import Pusher from 'pusher-js';
import authService from 'services/authService';
import {
  pusherKey, apiConfig
} from 'configs'

import './messaging.scss';

const PUSHER_AUTH_HEADER = { headers: { "Authorization": `Bearer ${authService.getToken()}` } };
const PUSHER_KEY = pusherKey.key;
const PUSHER_AUTH_URL = apiConfig.apis.find(i => i.id === 'pusher-auth').url;

export class MessagingPage {
  conversation;
  conversations;
  self;
  users;
  drivers;
  loading;
  contextViews;
  activeContextView;
  timer;

  constructor() {
    this.conversations = [],
      this.contextViews = {
        empty: 'empty',
        conversation: 'conversation',
        conversationSettings: 'conversationSettings',
        settings: 'settings'
      }
    this.activeContextView = this.contextViews.empty;
    this.conversation = null;

    this.preview = false;
    this.previewContent = null;

    this.channel = null;

    this.onPreview = this.onPreview.bind(this);
    this.onSentMessage = this.onSentMessage.bind(this);
    this.onConversationSelected = this.onConversationSelected.bind(this);
    this.onNewGroupSelected = this.onNewGroupSelected.bind(this);
    this.onChangeContext = this.onChangeContext.bind(this);
    this.openConversationSettings = this.openConversationSettings.bind(this);
    this.toggleConversationFiles = this.toggleConversationFiles.bind(this);
    this.onUpdateConversation = this.onUpdateConversation.bind(this);
  }

  attached() {
    this.loading = true;
    userService.getSelf().then(user => {
      this.self = user;
      driversService.get().then(drivers => {
        this.drivers = drivers;
        this.loading = false;
        this.loadConversations();
        this.subscribePusher();
      });
    });
  }

  detached() {
    clearTimeout(this.timer);
  }

  subscribePusher() {
    const pusher = new Pusher(PUSHER_KEY, {
      cluster: 'eu',
      authEndpoint: PUSHER_AUTH_URL,
      auth: PUSHER_AUTH_HEADER
    });
    
    this.channel = pusher.subscribe(`private-inbox-${this.self.accountId}`);
    this.channel.bind('message-added', (message) => {
      try {
        this.conversations.map(c => {
          if (c.id === message.conversationId) {
            const { content } = message.data.find(e => !e.contentType && e.content);
            c.lastMessage['message'] = content || '';
            c['status'] = 'received';
            c['badge'] = 1;
            c['lastMessage']['sender'] = message.sender;
            c['lastMessage']['sent'] = message.sent;
          }
        });
        this.orderConversations();
      } catch (e) {
        this.loadConversations();
      }
    });
  }

  loadConversations() {
    this.loading = true;
    messagingService.getConversations(this.drivers, this.self).then(conversations => {
      this.conversations = conversations || [];
      this.loading = false;
      this.orderConversations();
    }).catch(() => {
      this.loading = false;
    });
  }

  onConversationSelected() {
    if (this.conversations) {
      this.showConversationFiles = false;
      this.conversation = this.conversations.find(c => c.selected);
      this.activeContextView = this.contextViews.conversation;
    }
  }

  onChangeContext(conversation) {
    if (conversation) {
      this.conversation = this.conversations.find(c => c.id == conversation.id);
      if (this.conversation) {
        this.activeContextView = this.contextViews.conversation;
      } else {
        this.onChangeContext(null);
      }
    } else {
      this.conversation = null;
      this.activeContextView = this.contextViews.empty;
    }
  }

  onSentMessage() {
    this.orderConversations();
  }

  orderConversations() {
      this.conversations = this.conversations.sort((a, b) => a.lastMessage && new Date(b.lastMessage.sent).getTime() - new Date(a.lastMessage.sent).getTime())
      this.conversations = SortValueConverter.sort(this.conversations, 'lastMessage.sent', true);
  }

  openConversationSettings(conversation) {
    this.activeContextView = this.contextViews.conversationSettings;
  }

  toggleConversationFiles(conversation) {
    this.showConversationFiles = !this.showConversationFiles;
  }

  onNewGroupSelected() {
    this.conversation = null;
    this.activeContextView = this.contextViews.conversationSettings;
  }

  onUpdateConversation(conversation) {
    if (conversation) {
      this.conversations = messagingService.updateConversation(conversation).slice(0);
      this.conversations.forEach(c => (c.selected = c.id === conversation.id));
      this.onConversationSelected();
    }
  }

  onPreview(form) {
    this.preview = true;
    this.previewContent = form;
  }
}
