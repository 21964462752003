import {
  inject,
  bindable,
  observable,
  BindingEngine,
} from 'aurelia-framework';
import {
  DateTimeUtils
} from '@fonix/web-utils';
import {
  SortValueConverter
} from 'components/value-converters/filters/sort-list-vc';
import messagingService from 'services/api/messagingService';
import './messaging-conversation.scss';

export let printmode = true;

@inject(BindingEngine)
export class MessagingConversation {
  id;
  @bindable self;
  @bindable loading;
  @bindable conversations;
  @bindable conversation;
  @bindable channel;
  @observable messages;

  datetimeOptions;
  messageToSend;
  @observable newMessageInput;
  totalMessagesObservable;

  files;
  attachments;

  // @bindable onSendMessage;
  @bindable onConversationSettingsClicked;
  @bindable onConversationFilesClicked;
  @bindable onMessageSent;

  constructor(_BindingEngine) {
    this.bindingEngine = _BindingEngine;

    this.dateTimeOptions = {
      sameDay: "[today]",
      lastDay: "[yesterday]",
      lastWeek: "LL",
      sameElse: "LL"
    }
    this.messageToSend = null;
    this.previewAttachedImageSrc = null;

    this.attachmentDropdown = false;
    this.files = [];
    this.attachments = [];

    this.chatLoad = true;
    this.noMoreMessages = false;

    // this.openConversationSettings = this.openConversationSettings.bind(this);
    this.toggleConversationFiles = this.toggleConversationFiles.bind(this);
    this.onKeydown = this.onKeydown.bind(this);
    this.toggleAttachmentDropdown = this.toggleAttachmentDropdown.bind(this);
    this.onDropTarget = this.onDropTarget.bind(this);
    this.onDropClick = this.onDropClick.bind(this);
    this.onAttachmentInputChange = this.onAttachmentInputChange.bind(this);
    this.onAttachmentChanged = this.onAttachmentChanged.bind(this);
  }

  attached() {
    this.chatLoad = true;
    this.channel.bind('message-added', (message) => {
      const newMessage = {
        conversationId: message.conversationId,
        id: message.id,
        sent: message.sent,
        sender: message.sender,
        data: message.data,
      }
      if (!this.isPresent(newMessage)) {
        this.addMessage(newMessage);
      }
    });
  }

  detached() {
    this.totalMessagesObservable.dispose();
    if (this.newMessageInput) {
      this.newMessageInput.removeEventListener('keydown', this.onKeydown);
    }
  }

  conversationChanged() {
    if (this.messagesWrapper) {
      this.messagesWrapper.removeEventListener("scroll", this.scrollEvent);
    }
    this.messages = [];
    this.conversation['messages'] = [];
    this.noMoreMessages = false;
    this.chatLoad = true;
    if (this.conversation) {
      this.loadConversation();
    } else if (this.newMessageInput) {
      this.newMessageInput.removeEventListener('keydown', this.onKeydown);
    }
  }

  newMessageInputChanged() {
    if (this.newMessageInput) {
      this.newMessageInput.addEventListener('keydown', this.onKeydown);
    }
  }

  loadConversation() {
    this.files = [];
    this.attachments = [];
    this.loading = true;

    if (this.conversation) {
      messagingService.getMessages(this.conversation.id).then(messages => {
        this.buildMessages(messages);
        if (this.messagesWrapper) {

          // Lazy loading
          this.messagesWrapper.addEventListener("scroll", this.scrollEvent);
        }
      });
    }
  }

  scrollEvent = () => {
    if (this.messagesWrapper.scrollTop == 0 && !this.noMoreMessages) {
      messagingService.getMessages(this.conversation.id, this.messages[0])
        .then(messages => {
          if (messages && messages.length == 0) {
            this.noMoreMessages = true;
          } else {
            this.buildMessages(messages, true);
          }
        })
    }
  }

  buildMessages(messages, lazyLoad) {
    if (messages) {
      this.conversation['messages'] = lazyLoad ? messages.concat(this.conversation['messages']) : messages;
      this.conversation['totalMessages'] = this.conversation['messages'] && this.conversation['messages'].length;
      if (this.conversation && this.conversation.messages) {
        this.totalMessagesObservable = this.bindingEngine
          .propertyObserver(this.conversation, 'totalMessages')
          .subscribe((newValue, oldValue) => {
            this.totalMessagesChanged(newValue, oldValue)
          });

        this.conversation.messages.forEach(m => {
          if (!m.sent) {
            m.sent = DateTimeUtils.toAPI(Date.now());
          }
        });
        this.messages = this.conversation.messages;

        if (lazyLoad) {
          this.messagesWrapper.scrollTo(0, 500);
          setTimeout(() => {
            let height = 0;
            this.messages.forEach((elem, i) => {
              if(i > this.messages.length - (messages.length + 1)) {
                const element = document.getElementById(`message-${i}`);
                height = height + element.scrollHeight;
              }
            })
            this.messagesWrapper.scrollTo(0, height);
          }, 0);
        }
      }
    }
  }

  downloadById(attachment,printmode) {
    messagingService.downloadById(attachment, printmode);
  }

  messagesChanged() {
    this.buildMessagesList();
  }

  totalMessagesChanged() {
    this.buildMessagesList();
  }

  buildMessagesList() {
    this.mesagesList = SortValueConverter.sort(this.messages, 'sent', true);
    if (this.chatLoad) {
      this.scrollToBottom();
    }
  }

  addMessage(newMessage) {
    this.conversation['messages'].push(newMessage);
    this.conversation['totalMessages'] = this.conversation.messages.length;
  };

  sendMessage() {
    if ((this.messageToSend && this.messageToSend.length) || (this.attachments && this.attachments.length)) {
      this.chatLoad = true;
      const newMessage = {
        conversationId: this.conversation.id,
        sender: { id: this.self.id, type: 'user', metadata: { name: this.self.name } },
        sent: DateTimeUtils.toAPI(Date.now()),
        data: [{ content: this.messageToSend }],
        id: undefined
      }

      if (this.onSendMessage) {
        this.onSendMessage(newMessage, this.conversation.id)
      }
      this.files = [];
      this.attachments = [];
      this.messageToSend = null;
    }
  }

  onSendMessage(message, conversationId) {
    if (message) {
      messagingService.sendMessage(message, conversationId, this.self).then((id) => {
        if (!id) {
          this.messages.map(m => {
            if (m.sent == message.sent) {
              m.status = 'error';
            }
          })
        } else {
          message.id = id;
          if (!this.isPresent(message)) {
            this.addMessage(message);
          }

          this.conversations.map((element) => {
            if (element.id == conversationId && element.lastMessage) {
              element.lastMessage.message = message.data[0].content;
              element.lastMessage.sent = message.sent;
              element.lastMessage.sender = { type: 'user', id: this.self.id };
              element.badge = 0;
            }
          })
          this.onMessageSent();
        }
      })
    }
  }

  isPresent = ({ id }) => {
    return this.conversation.messages.some(elem => elem.id == id);
  }

  scrollToBottom() {
    this.chatLoad = false;
    setTimeout(() => {
      if (!this.messagesWrapper) return;
      if (this.messagesWrapper.scrollHeight > this.messagesWrapper.clientHeight) {
        this.messagesWrapper.scrollTo(0, this.messagesWrapper.scrollHeight)
      }
      this.loading = false;
    }, 250);
  }

  // openConversationSettings(e, conversation) {
  //   if (this.onConversationSettingsClicked) {
  //     this.onConversationSettingsClicked(conversation);
  //   }
  // }

  toggleConversationFiles(e, conversation) {
    this.onConversationFilesClicked(conversation);
  }

  onKeydown(e) {
    if (e.keyCode == 13 && !e.shiftKey) {
      this.sendMessage();
      e.preventDefault();
    }
  }

  toggleDate(index, message, messagesList) {
    if (!messagesList[index + 1] || messagesList[index + 1].sent.substring(0, 10) != message.sent.substring(0, 10)) {
      return true;
    } else {
      return false;
    }
  }
  
  fileUrl(file) {
    let url = null;

    if (typeof file === 'string') {
      url = file;
    }

    if (file instanceof Blob) {
      url = URL.createObjectURL(file);
    }

    if (url) {
      return url
    }

  }

  imageExtension(file) {
    return (file && file.type.match('image/*')) ? true : false
  }

  // ATTACHMENT

  toggleAttachmentDropdown() {
    if (this.attachments && this.attachments.length) {
      this.onDropClick();
    } else {
      this.attachmentDropdown = !this.attachmentDropdown;
    }
  }

  onDropTarget(e) {
    let inputFiles = (e.files || []);
    if (inputFiles) {
      let files = [];
      for (var i = 0; i < inputFiles.length; i++) {
        files.push(inputFiles[i]);
      }

      this.files = files;
    }
  }

  onDropClick() {
    this.attachmentDropdown = false;

    if (this.attachmentInputEl) {
      this.attachmentInputEl.click();
    }
  }

  onAttachmentInputChange(e) {
    let inputFiles = (e.target && (e.target.files || [])) || null;
    if (inputFiles) {
      let files = [];
      for (var i = 0; i < inputFiles.length; i++) {
        files.push(inputFiles[i]);
      }

      this.files = files;
    }
    //clear
    e.target.value = '';
  }

  onAttachmentChanged(attachments) {
    this.attachments = attachments;
  }
}
